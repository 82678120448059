import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Section } from '../../components/section/section';
import { ShareFacebookSection } from '../share-facebook-section/share-facebook-section';
import { ConnectedContactSuggestionForm } from '../../contact-suggestion/contact-suggestion-form/contact-suggestion-form';
import { IContactSuggestionFormValues } from '../../contact-suggestion/contact-suggestion-form-values';
import { contactSuggestionFormSubmitted } from '../../contact-suggestion/model/contact-suggestion.actions';
import { ShareUrl } from '../share-url/share-url';
import { getContactSuggestionMessage } from '../../contact-suggestion/model/selectors/get-contact-suggestion-message.selector';
import { getMemorialPageUrl } from '../../model/configuration/selectors/get-memorial-page-url.selector';

import styles from './share-section.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {};

export const ShareSection = ({ className, ...props }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const memorialPageUrl = useSelector(getMemorialPageUrl);
    const contactSuggestionMessage = useSelector(getContactSuggestionMessage);

    const onSubmitForm = (values: IContactSuggestionFormValues) => {
        dispatch(contactSuggestionFormSubmitted(values));
    };

    return (
        <Section
            className={className}
            headerClassName={styles.SectionHeader}
            headerText={t('share.title')}
            {...props}
        >
            <p className={styles.SectionDescription}>
                {t('share.description')}
            </p>
            <ShareFacebookSection />
            <ConnectedContactSuggestionForm
                onSubmit={onSubmitForm}
                contactSuggestionMessage={contactSuggestionMessage}
            />
            <ShareUrl className={styles.ShareUrl} url={memorialPageUrl} />
        </Section>
    );
};
