import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg';
import { ReactComponent as ApproveIcon } from '../../assets/icons/approve-icon.svg';

import styles from './share-url.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    url: string;
};

const resetCopyDelay = 2000; // resetEmail copy state after 2 seconds

export const ShareUrl = ({ url, ...props }: Props) => {
    const { t } = useTranslation();
    const [copied, setCopied] = useState<boolean>(false);

    const Icon = copied ? ApproveIcon : CopyIcon;

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout> | null = null;

        if (copied) {
            timeout = setTimeout(() => {
                setCopied(false);
            }, resetCopyDelay);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [copied]);

    return (
        <div {...props}>
            <span className={styles.ShareTitle}>
                {t('share.shareBoxTitle')}
            </span>
            <div
                className={styles.ShareBox}
                onClick={() => {
                    copy(url);
                    setCopied(true);
                }}
            >
                <div className={styles.ShareLink}>{url}</div>
                <div className={styles.ShareIconBox}>
                    <Icon className={styles.ShareIcon} />
                </div>
            </div>
            {copied && (
                <span className={styles.CopiedText}>
                    {t('share.shareBoxCopiedText')}
                </span>
            )}
        </div>
    );
};
