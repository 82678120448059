import { createReducer } from 'typesafe-actions';
import { assign, pick, keys } from 'lodash';

import { ILanguage } from '../../interfaces/language';
import { RootActions } from '../../store/actions';
import {
    getBaseUrlFromLocation,
    getSlugFromLocation,
} from '../../utils/parse-location';
import { PrivacyMode } from '../../interfaces/privacy-mode';
import { fetchPageActions } from '../../api/page/page-api.actions';
import { ICharityDto } from '../../api/DTOs/charity.dto';
import { LanguageCode } from '../../utils/get-language-codes';

import { languageChanged, privacyModeDetected } from './configuration.actions';
import { availableLanguages } from './available-languages';

export interface IConfigurationStore {
    language: ILanguage;
    languages: ILanguage[];
    slug: string;
    baseUrl: string;
    privacy: PrivacyMode | null;
    flowerShopEnabled: boolean;
    donationEnabled: boolean;
    donationOrganizationId: string | null;
    donationOrganizationName: string | null;
    donationOrganizationDescription: string | null;
    donationOrganizationLogo: string | null;
    donationFee: number;
    donationCurrency: string | null;
    locale: LanguageCode | null;
    condolencesEnabled: boolean;
    companyLogoOriginal: string | null;
    companyLogoThumb: string | null;
    companyWebsiteUrl: string | null;
    funeralHomeBranchLogoOriginal: string | null;
    funeralHomeBranchLogoThumb: string | null;
    funeralHomeBranchWebsiteUrl: string | null;
    theme: string;
    themeReceived: boolean;
    pageId: string | null;
    heartsEnabled: boolean;
    publicMessageForPrivateCeremony: string | null;
    popularProductsDescription: string | null;
    flowerShopCategoriesDescription: string | null;
    imageUploadingForCondolencesEnabled: boolean;
    dinteroPaymentEnabled: boolean;
}

const initialState: IConfigurationStore = {
    language: availableLanguages[0],
    languages: availableLanguages,
    /**
     * TODO: Maybe it shouldnt be in this reducer
     */
    slug: getSlugFromLocation(window.location),
    baseUrl: getBaseUrlFromLocation(window.location),
    privacy: null,
    flowerShopEnabled: false,
    donationEnabled: false,
    donationOrganizationId: null,
    donationOrganizationName: null,
    donationOrganizationDescription: null,
    donationOrganizationLogo: null,
    donationFee: 0,
    donationCurrency: null,
    locale: null,
    condolencesEnabled: false,
    companyLogoOriginal: null,
    companyLogoThumb: null,
    funeralHomeBranchLogoOriginal: null,
    funeralHomeBranchLogoThumb: null,
    funeralHomeBranchWebsiteUrl: null,
    companyWebsiteUrl: null,
    theme: 'default',
    themeReceived: false,
    pageId: null,
    heartsEnabled: true,
    publicMessageForPrivateCeremony: null,
    popularProductsDescription: null,
    flowerShopCategoriesDescription: null,
    imageUploadingForCondolencesEnabled: false,
    dinteroPaymentEnabled: false,
};

export const configurationReducer = createReducer<
    IConfigurationStore,
    RootActions
>(initialState)
    .handleAction(languageChanged, (state, action) => ({
        ...state,
        language: action.payload.language,
    }))
    .handleAction(fetchPageActions.success, (state, action) => {
        const automaticallyUpdatedState: IConfigurationStore = assign(
            {},
            state,
            pick(action.payload.data.attributes, keys(state)),
        );

        // @TODO move it to separate charity or donation store
        const charityData = action.payload.included.find(
            (inclusion) => inclusion.type === 'charity',
        ) as ICharityDto;

        const manuallyUpdatedState: Partial<IConfigurationStore> = {
            pageId: action.payload.data.id,
            themeReceived: true,
            theme: automaticallyUpdatedState.theme || initialState.theme,
            donationEnabled: action.payload.data.attributes.donationsEnabled,
            donationOrganizationId: charityData?.id || null,
            donationOrganizationName: charityData?.attributes?.name || null,
            donationOrganizationDescription:
                (charityData?.attributes.isFhCharity &&
                    action.payload.data.attributes.charityDescription) ||
                charityData?.attributes.description,
            donationCurrency: charityData?.attributes?.currency || null,
            donationOrganizationLogo: charityData?.attributes?.logo || null,
            donationFee: charityData?.attributes?.fee || 0,
        };

        return {
            ...automaticallyUpdatedState,
            ...manuallyUpdatedState,
        };
    })
    .handleAction(privacyModeDetected, (state, action) => ({
        ...state,
        privacy: action.payload.privacy,
    }));
